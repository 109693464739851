import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiPhone, mdiMapMarker, mdiAccount } from "@mdi/js";
import parse from "html-react-parser";

const query = graphql`
	query {
		strapiPageListeDesProfessionnelsCertifies {
			title
			slug
			body
			seo {
				metaDescription
				metaTitle
			}
		}
		certified {
			id
			data {
				name
				firstname
				postalCode
				city
				phone
				email
				presentation
				lat
				lon
			}
		}
	}
`;

const ListeDesProfessionnelsCertifies = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageListeDesProfessionnelsCertifies;

	const position = [48.85888, 2.34694]; //Paris

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className="sub-container">{parse(page.body)}</div>
					{typeof window !== "undefined" ? (
						<div className={`map-container`}>
							<MapContainer center={position} zoom={5} scrollWheelZoom={true}>
								<TileLayer
									attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								{data.certified.data.map((certified, index) => (
									<Marker position={[certified.lat, certified.lon]} key={index}>
										<Popup>
											<p>
												<b>
													{certified.name} {certified.firstname}
												</b>
											</p>
											{certified.postalCode ? (
												<p>
													<Icon
														path={mdiMapMarker}
														size={1}
														className={`leaflet-popup__icon`}
													/>
													&nbsp;&nbsp;{certified.postalCode} {certified.city}
												</p>
											) : (
												``
											)}
											{certified.email ? (
												<p>
													<Icon
														path={mdiEmailOutline}
														size={1}
														className={`leaflet-popup__icon`}
													/>
													&nbsp;&nbsp;{certified.email}
												</p>
											) : (
												``
											)}
											{certified.phone ? (
												<p>
													<Icon
														path={mdiPhone}
														size={1}
														className={`leaflet-popup__icon`}
													/>
													&nbsp;&nbsp;{certified.phone}
												</p>
											) : (
												``
											)}
											{certified.presentation ? (
												<p style={{ whiteSpace: `break-spaces` }}>
													<Icon
														path={mdiAccount}
														size={1}
														className={`leaflet-popup__icon`}
													/>
													&nbsp;&nbsp;{certified.presentation}
												</p>
											) : (
												``
											)}
										</Popup>
									</Marker>
								))}
							</MapContainer>
						</div>
					) : (
						`window object is undefined`
					)}
				</div>
			</div>
		</Layout>
	);
};

export default ListeDesProfessionnelsCertifies;
